import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "../scss/legal.scss"
import CookieConsentContext from "../context/CookieContext";
import CookiePopup from "../components/cookiepopup";

const CookiesPage = () =>  (
  <CookieConsentContext.Consumer>
    {({ state, dispatch, popupIsOpen }) => (

      <div className="legal">
        <Helmet
          htmlAttributes={{
            class: 'legal-page'
          }}
          bodyAttributes={{
          class: 'legal-page'
        }}>
          <title>Πολιτική cookies</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div>
          <h1>Πολιτική cookies</h1>
          <div><p>Η παρούσα Πολιτική Cookies
            παρέχει πληροφορίες αναφορικά με την χρήση των Cookies και Παρόμοιων
            Τεχνολογιών από τον ιστότοπο της Souldesign, («εμείς» ή “εμάς”)
            ή/και των συνδεδεμένων της εταιρειών («Ιστότοπος»). Περιγράφει επίσης
            τι είδους επιλογές μπορεί να έχετε σχετικά με τα Cookies και τις
            Παρόμοιες Τεχνολογίες.</p>
            <p>Η παρούσα Πολιτική Cookies αποτελεί μέρος και ενσωματώνεται στους
              όρους χρήσης του Ιστοτόπου και μπορεί να υπόκειται σε αλλαγές κατά
              καιρούς. Συνίσταται να ελέγχετε τακτικά την Πολιτική Cookies
              προκειμένου να ενημερώνεστε για οποιεσδήποτε αναθεωρήσεις. Σχετικές
              ειδοποιήσεις για ουσιαστικές αλλαγές μπορεί να εμφανίζονται επίσης
              στον Ιστότοπο. Με την επίσκεψή σας στον Ιστότοπο συμφωνείτε να
              αποδέχεστε οποιεσδήποτε αλλαγές πραγματοποιούνται σε αυτή την
              Πολιτική.</p>
            <p>Σε ορισμένες περιπτώσεις ενδέχεται να χρησιμοποιούμε τεχνολογίες
              εντοπισμού για να συγκεντρώσουμε πληροφορίες που ταυτοποιούν φυσικά
              πρόσωπα ή ενδέχεται να ταυτοποιούν φυσικά πρόσωπα, εάν τις
              συνδυάσουμε με άλλες πληροφορίες. Σε αυτές τις περιπτώσεις, θα
              εφαρμόζεται, ταυτόχρονα με την παρούσα Πολιτική Cookies, η Πολιτική
              Απορρήτου.</p>
            <h3><strong>Τι είναι cookies</strong></h3>
            <p>Τα Cookies είναι μικρά αρχεία κειμένου – που αποτελούνται συνήθως
              από γράμματα και αριθμούς και αποθηκεύονται στην συσκευή σας από
              έναν εξυπηρετητή δικτύου (website server).</p>
            <p>Τα Cookies διακρίνονται σε «<strong>Cookies Συνεδρίας</strong>» και
              «<strong>Επίμονα Cookies</strong>» ανάλογα με τον χρόνο που
              παραμένουν αποθηκευμένα στη συσκευή σας. Τα «Cookies Συνεδρίας»
              αποθηκεύονται στη συσκευή σας την στιγμή που επισκέπτεστε τον
              Ιστότοπο και διαγράφονται αυτόματα, αφού κλείσετε το σύστημα
              περιήγησης (browser). Τα «Επίμονα cookies» αποθηκεύονται στη συσκευή
              σας τη στιγμή που επισκέπτεστε τον Ιστότοπο και παραμένουν
              αποθηκευμένα και μετά το κλείσιμο του συστήματος περιήγησης. Τα
              «Επίμονα cookies» θα ενεργοποιηθούν ξανά αυτόματα όταν ανοίξετε το
              σύστημα περιήγησης και περιηγηθείτε στο διαδίκτυο.</p>
            <p><strong>Cookies πρώτου μέρους και τρίτων μερών</strong> – Ανάλογα
              με τον Ιστότοπο ή τον ιστοχώρο (domain) που εγκαθιστά το cookie,
              χαρακτηρίζονται τα cookies ως «πρώτου» ή «τρίτου» μέρους. Τα cookies
              πρώτου μέρους σε γενικές γραμμές είναι cookies που εγκαθίστανται από
              τον Ιστότοπο που επισκέπτεται ο χρήστης – τον Ιστότοπο που
              εμφανίζεται στο παράθυρο URL. Τα cookies τρίτου μέρους είναι cookies
              που εγκαθίστανται από έναν ιστοχώρο (domain) διαφορετικό από εκείνον
              που επισκέφθηκε ο χρήστης. Εάν ο χρήστης επισκεφθεί έναν ιστότοπο
              και μία διαφορετική εταιρεία εγκαταστήσει ένα cookie μέσω αυτού του
              ιστοτόπου, το εν λόγω cookie θα είναι τρίτου μέρους.</p>
            <h3><strong>Γιατί χρησιμοποιούμε Cookies</strong></h3>
            <p>Χρησιμοποιούμε cookies για να σας δώσουμε την καλύτερη δυνατή
              εμπειρία περιήγησης. Ορισμένα cookies είναι τεχνικά απαραίτητα για
              την ορθή χρήση του Ιστοτόπου. Άλλα cookies χρησιμοποιούνται για την
              εκτέλεση λειτουργιών όπως η διαφήμιση, η απόκτηση στατιστικών
              στοιχείων (δηλαδή πόσοι επισκέπτες είδαν συγκεκριμένες σελίδες στον
              Ιστότοπο μας ή βλέπουν τις διαφημίσεις μας όταν χρησιμοποιούν άλλους
              ιστοτόπους) και η διευκόλυνση της διάδρασής σας με μέσα κοινωνικής
              δικτύωσης.</p>
            <br/>
            <h3><strong>Απολύτως Απαραίτητα Cookies</strong></h3>
            <p>Αυτά τα cookies είναι απολύτως απαραίτητα για να σας παρέχουμε τις
              ζητηθείσες από εσάς υπηρεσίες μέσω του Ιστοτόπου. Είναι αναγκαία για
              να σας παρέχουν τη δυνατότητα να επισκέπτεσθε τον Ιστότοπο μας και
              να χρησιμοποιείτε σωστά τα χαρακτηριστικά του. Βασικές λειτουργίες,
              όπως οι ακόλουθες, στηρίζονται σε αυτά τα σημαντικά cookies:</p>
            <ul>
              <li>Να αναγνωρίζονται οι προσωπικές σας πληροφορίες, τις οποίες
                καταχωρείτε στον Ιστότοπο
              </li>
              <li>Να ανακτάτε πληροφορίες για τη συναλλαγή σας μετά την ολοκλήρωσή
                της και
              </li>
              <li>Γενικά, να σας παρέχουμε υπηρεσίες και πληροφορίες κατόπιν δικού
                σας αιτήματος.
              </li>
            </ul>
            <div className="cookies-table__wrapper">
              <table className="text--sm cookies-table">
                <thead>
                  <tr>
                    <th>Όνομα</th>
                    <th>Πάροχος</th>
                    <th>Σκοπός</th>
                    <th>Διάρκεια</th>
                    <th>Τύπος</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>consent</td>
                    <td>quarantine.souldesign.gr</td>
                    <td>Αποθηκεύει τις ρυθμίσεις του χρήστη σχετικά με τα cookies
                    </td>
                    <td>1 έτος</td>
                    <td>HTTP</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br/>
            <h3><strong>Cookies απόδοσης</strong></h3>
            <p>Αυτά τα cookies, τα οποία μερικές φορές ονομάζονται τα cookie
              ανάλυσης, συλλέγουν πληροφορίες για το πώς οι χρήστες
              χρησιμοποιούν τον Ιστότοπο, προκειμένου να είμαστε σε θέση να
              αναθεωρούμε και να βελτιώνουμε τον Ιστότοπο και την εμπειρία του
              επισκέπτη. Αυτά τα cookies δεν συγκεντρώνουν πληροφορίες που
              ταυτοποιούν τον επισκέπτη. Τέτοια cookies συλλέγουν πληροφορίες
              αναφορικά με:</p>
            <ul>
              <li>Σελίδες που οι χρήστες επισκέπτονται συχνότερα,</li>
              <li>Διαφημίσεις ή προσφορές που έχουν δει οι χρήστες,</li>
              <li>Συνδέσμους (links) που έχουν επιλέξει οι χρήστες,</li>
              <li>Μηνύματα λάθους που μπορεί να λάβουν οι χρήστες κατά τη
                διάρκεια της περιήγησής τους στον Ιστότοπο, κτλ.
              </li>
            </ul>
            <div className="cookies-table__wrapper">
              <table className="text--sm cookies-table">
                <thead>
                <tr>
                  <th>Όνομα</th>
                  <th>Πάροχος</th>
                  <th>Σκοπός</th>
                  <th>Διάρκεια</th>
                  <th>Τύπος</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>_ga_XLJ7LHSS4G</td>
                  <td>.souldesign.gr</td>
                  <td>Χρησιμοποιείται για να ρυθμίσει το ρυθμό αιτήματος</td>
                  <td>1 λεπτό</td>
                  <td>HTTP</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>.souldesign.gr</td>
                  <td>Χρησιμοποιείται για τη διάκριση των χρηστών</td>
                  <td>24 ώρες</td>
                  <td>HTTP</td>
                </tr>
                <tr>
                  <td>ga</td>
                  <td>.souldesign.gr</td>
                  <td>Χρησιμοποιείται για τη διάκριση των χρηστών</td>
                  <td>2 έτη</td>
                  <td>HTTP</td>
                </tr>
                </tbody>
              </table>
            </div>
            <br/>
            <h3><strong>Cookies τρίτων μερών</strong></h3>
            <p>Προκειμένου να σας παρέχουμε πιο ενδιαφέρον περιεχόμενο και να
              σας βοηθήσουμε να αλληλεπιδράσετε με τους λογαριασμούς σας σε
              μέσα κοινωνικής δικτύωσης, ο Ιστότοπος μπορεί να ενσωματώνει
              περιεχόμενο και υπηρεσίες τρίτων μερών (π.χ. «plugins» από
              Facebook, Twitter, YouTube, Instagram, Pinterest, κτλ.) ή
              συνδέσμους σε ιστοτόπους τρίτων μερών. Όταν επισκέπτεσθε
              συνδέσμους ή/και ιστοτόπους τρίτων μερών, υπόκεισθε στην
              πολιτική cookies αυτών των τρίτων μερών. Σε αυτή την περίπτωση,
              ενεργείτε με δική σας ευθύνη και δεν αναλαμβάνουμε καμία
              απολύτως ευθύνη για οποιοδήποτε θέμα προκύψει από την αποθήκευση
              cookies στη συσκευή σας από οποιονδήποτε τέτοιο ιστότοπο τρίτου
              μέρους.</p>
            <h2><strong>Πώς να ελέγχετε τα cookies</strong></h2>
            <p>Οι περισσότεροι περιηγητές διαδικτύου αποδέχονται αυτόματα τα
              cookies, ωστόσο παρέχουν μέσα ελέγχου, τα οποία σας επιτρέπουν
              να μπλοκάρετε τα cookies ή να τα διαγράψετε. Έχετε τη δυνατότητα
              να ενεργοποιήσετε ή να απενεργοποιήσετε την εγκατάσταση cookies
              στην συσκευή σας αλλάζοντας τις ρυθμίσεις στο σύστημα περιήγησης
              που χρησιμοποιείτε ανά πάσα στιγμή. Μπορείτε επίσης να ρυθμίσετε
              το σύστημα περιήγησης που χρησιμοποιείτε κατάλληλα προκειμένου
              να σας ενημερώνει πριν την εγκατάσταση ενός cookie στην συσκευή
              σας ή να ακολουθήσετε τις οδηγίες του συστήματος περιήγησης για
              να διαγράψετε όσα cookies έχουν ήδη εγκατασταθεί. Εάν
              χρησιμοποιείτε ποικίλες συσκευές, πρέπει να ρυθμίσετε το σύστημα
              περιήγησης σύμφωνα με τις προτιμήσεις σας σε κάθε μία από τις
              συσκευές σας.</p>
            <p>Εάν επιλέξετε να απενεργοποιήσετε όλα τα cookies, ενδέχεται να
              μην μπορείτε να απολαύσετε πλήρως τις διαδραστικές λειτουργίες
              του Ιστοτόπου μας ή να λάβετε τις υπηρεσίες μας μέσω του
              Ιστοτόπου μας.</p>
            <p>Η διαγραφή των cookies δεν διαγράφει αντικείμενα Flash
              εφαρμογών. Μπορείτε να μάθετε περισσότερα σχετικά με τα
              αντικείμενα Flash εφαρμογών, συμπεριλαμβανομένου του τρόπου
              ελέγχου και απενεργοποίησης αυτών στον ιστότοπο της Adobe.
              Μπορείτε να βρείτε πρόσθετες πληροφορίες σχετικά με cookies και
              παρόμοιες τεχνολογίες που χρησιμοποιούνται σε διάφορους
              ιστότοπους μέσω συστήματος περιήγησης που χρησιμοποιείτε ή άλλων
              πόρων του διαδικτύου.</p>
            <p>Για να διαχειρίζεστε τα cookies μέσω του περιηγητή σας,
              επιλέξτε τους ακόλουθους υπερσυνδέσμους για να ανακατευθυνθείτε
              στις οδηγίες του περιηγητή σας:</p>
            <p><a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank" rel="noreferer">Microsoft Windows
              Explorer</a><br/>
              <a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank" rel="noreferer">Mozilla Firefox</a><br/>
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en"
                target="_blank" rel="noreferer">Google Chrome</a><br/>
              <a href="https://support.apple.com/kb/ph19214?locale=en_US"
                 target="_blank" rel="noreferer">Apple Safari</a></p>
            <p>Μπορείτε να μάθετε περισσότερα για τα cookies στις ακόλουθες
              ιστοσελίδες τρίτων μερών:<br/>
              AllAboutCookies: <a
                href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>
              <br/>
              Cookiepedia: <a
                href="http://cookiepedia.co.uk/">http://cookiepedia.co.uk/</a>
            </p>
            <h2><strong>Τί είναι οι παρόμοιες τεχνολογίες;</strong></h2>
            <p>Οι ετικέτες Pixel, τα web beacons, οι ενσωματωμένοι κώδικες
              (embedded scripts) και τα clear GIFs (μορφή ανταλλαγής γραφικών)
              είναι μικροσκοπικά αρχεία που χρησιμοποιούνται για την
              παρακολούθηση της πλοήγησης σε έναν ιστότοπο και μπορούν να
              χρησιμοποιηθούν μαζί με cookies. Τα αντικείμενα Flash (τοπικά
              κοινόχρηστα αντικείμενα) είναι μικρά αρχεία που είναι
              αποθηκευμένα στη συσκευή σας από έναν ιστότοπο που χρησιμοποιεί
              την τεχνολογία Flash player της Adobe.</p>
            <h3><strong>Web beacons και ενσωματωμένοι κώδικες (embedded
              scripts)</strong></h3>
            <p>Τα Web beacons και οι ενσωματωμένοι κώδικες (embedded scripts)
              είναι άλλες τεχνολογίες που χρησιμοποιούμε στον Ιστότοπό μας,
              καθώς και σε ορισμένα μηνύματα ηλεκτρονικού ταχυδρομείου και
              διαφημίσεις.</p>
            <p>Web beacons (ή “tags”) είναι κομμάτια κώδικα προγραμματισμού
              που περιλαμβάνονται σε ιστοσελίδες, μηνύματα ηλεκτρονικού
              ταχυδρομείου και διαφημίσεις, τα οποία γνωστοποιούν σε εμάς
              ή/και σε τρίτα μέρη που παρέχουν υπηρεσίες για λογαριασμό μας,
              πότε προβλήθηκαν ή επιλέχθηκαν αυτές οι ιστοσελίδες, τα μηνύματα
              ηλεκτρονικού ταχυδρομείου ή οι διαφημίσεις.</p>
            <p>Οι ενσωματωμένοι κώδικες (embedded scripts) είναι κομμάτια
              κώδικα προγραμματισμού που περιλαμβάνονται σε ορισμένες από τις
              ιστοσελίδες μας και μετρούν πώς χρησιμοποιείτε αυτές τις
              ιστοσελίδες, για παράδειγμα, ποιους συνδέσμους επιλέγετε.
              Χρησιμοποιούμε αυτές τις πληροφορίες για να βελτιώνουμε τον
              Ιστότοπό μας, να διαμορφώνουμε τον Ιστότοπό μας στα πιθανά
              ενδιαφέροντά σας και για να διενεργούμε έρευνες αγοράς. Μπορείτε
              να απενεργοποιήσετε τέτοιες λειτουργικότητες κώδικα, όπως η
              JavaScript, μέσω του συστήματος περιήγησης που χρησιμοποιείτε
              (παρακαλούμε αναφερθείτε στη λειτουργία βοήθειας του συστήματος
              περιήγησης). Σημειώστε ότι εάν απενεργοποιήσετε τις
              λειτουργικότητες κώδικα, ο Ιστότοπος ενδέχεται να μην λειτουργεί
              σωστά.</p>
            <h3><strong>Απόρρητο</strong></h3>
            <p>Δεσμευόμαστε να χρησιμοποιούμε ορθές πρακτικές απορρήτου. Για
              περισσότερες πληροφορίες σχετικά με την προσέγγισή μας στην
              προστασία της ιδιωτικής ζωής, ανατρέξτε στην Πολιτική
              Απορρήτου.</p>
            <h3><strong>Πώς να επικοινωνήσετε μαζί μας</strong></h3>
            <p>Εάν έχετε οποιεσδήποτε ερωτήσεις για το πώς χρησιμοποιούμε τα
              Cookies, μπορείτε να επικοινωνήσετε μαζί μας χρησιμοποιώντας τις
              παρακάτω αναφερόμενες πληροφορίες. Παρακαλούμε να συμπεριλάβετε
              τα στοιχεία επικοινωνίας σας και λεπτομερή περιγραφή της απορίας
              σας σχετικά με τα Cookies.</p>
            <p>Στοιχεία επικοινωνίας <strong>Souldesign</strong><br/>
              Email: hello@souldesign.gr<br/>
              Τηλέφωνο: +30 210 34 16 904<br/>
              Δ/νση: Μυκάλης 11, 10435 Αθήνα</p>
            <p>Ενδέχεται να τροποποιήσουμε αυτή την Ενημέρωση ανά πάσα
              στιγμή.</p></div>
        </div>
        {popupIsOpen && <CookiePopup dispatch={dispatch} value={state} />}
      </div>
  )}
  </CookieConsentContext.Consumer>
)

export default CookiesPage
